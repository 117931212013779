.contact-bulletpoints-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .contact-bulletpoints-container {
        flex-direction: column;
    }
}

.contact-bulletpoint-item {
    flex: 1;
    margin: 50px;
    padding: 10px;
    border: 0px solid;
    border-radius: 10px;
}