.App {
  text-align: center;
  background-color: #9e9e9e;
  display: flex;
  align-content: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

p {
  font-family: 'DinNextLtProRegular', 'serif';
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'DinNextLtProBold', 'serif';
  font-weight: bold;
  font-style: normal;
}

strong {
  font-family: 'DinNextLtProBold', 'serif';
}

h2 {
  text-align: center;
}


@media only screen and (max-width: 1280px) {
  .content {
    max-width: 100%;
    min-width: 100%;
  }
}

@media only screen and (max-width: 1600px) {
  .content-element-row {
    flex-direction: column !important;
  }
}

.content {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.7);
  max-width: 70%;
  display: flex;
  flex-direction: column;
}

.content-element {
  padding: 5px;
  margin: 10px;
  border: 1px solid white;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  flex: 1
}

.content-element-row {
  display: flex;
  flex-direction: row;
}