.bulletpoints-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .bulletpoints-container {
        flex-direction: column;
    }
}

.bulletpoint-item {
    flex: 1;
    margin: 30px;
    padding: 10px;
    border: 0px solid;
    border-radius: 10px;
    background-color: whitesmoke;
}

.bulletpoint-item:hover {
    background-color: whitesmoke;
}