.social-bulletpoints-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    min-height: 100%;
}

@media only screen and (max-width: 768px) {
    .social-bulletpoints-container {
        flex-direction: column;
    }
}

.social-bulletpoint-item {
    display: flex;
    align-content: center;
    justify-content: center;
    flex: 1;
    margin: 50px;
    padding: 10px;
}

.social-icon {
    flex: 1;
    align-self: center;
}
