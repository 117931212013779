.break {
    margin: 5px
}

#contact-us-button {
    font-weight: bold;
    margin: 20px;
    padding: 20px
}

.welcome-text-container {
    padding: 50px;
    font-size: 20px;
    text-align: justify-all;
}

@media only screen and (max-width: 768px) {
    .welcome-text-container {
        font-size: 18px;
    }
}